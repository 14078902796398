import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeaderSection from '../components/sections/HeaderSection';
import JannikWeichertImage from '../components/images/about-us/JannikWeichert';
import JanPapenbrockImage from '../components/images/about-us/JanPapenbrock';
import LogoImage from '../images/logo.svg';
import RocketIcon from '../images/icons/rocket.svg';
import LightBulbIcon from '../images/icons/light-bulb.svg';
import LoveIcon from '../images/icons/love.svg';
import LikeIcon from '../images/icons/like.svg';

/* eslint-disable react/prop-types */
const EmployeeRow = ({
  name,
  position,
  quote,
  hashtag,
  image: ImageComponent,
}) => (
  <div className="flex flex-row flex-wrap box w-full relative pb-6 my-12">
    <div className="w-64 md:absolute md:-ml-12 md:-mt-24">
      <ImageComponent />
    </div>
    <div className="hidden sm:block w-1/2 lg:w-1/3 xl:w-1/4" />
    <div className="flex flex-col items-start w-full md:w-1/2 lg:w-2/3 mt-4 md:mt-0">
      <h2>{name}</h2>
      <p>{position}</p>

      <p className="font-bold mt-12">{quote}</p>
      <p className="text-brand font-semibold">{hashtag}</p>
    </div>

    <img src={LogoImage} className="absolute pin-r pin-t w-6 -mr-3 -mt-3" alt="Hochzehn Logo" />
  </div>
);

/* eslint-enable react/prop-types */

const AboutUsPage = () => (
  <Layout>
    <SEO title="Über Uns" keywords={[]} />
    <HeaderSection>
      <h1>
        Wir sind hochzehn.
      </h1>
    </HeaderSection>
    <div className="flex flex-col">
      <div className="flex flex-col justify-center md:px-8">
        <EmployeeRow
          name="Jannik Weichert"
          position="CEO & Softwareentwickler"
          quote="„Always be ahead of your plane.“"
          hashtag="#teammünster"
          image={JannikWeichertImage}
        />

      </div>
      <div className="flex flex-row flex-wrap justify-center p-12 bg-grey-lightest">
        <div className="flex flex-col md:text-center w-full">
          <h2>Unsere Philosopie</h2>
        </div>
        <div className="flex flex-col max-w-sm">
          <div className="flex flex-row flex-wrap md:flex-no-wrap mt-12 items-center">
            <div className="flex flex-col w-24 md:m-8">
              <img src={RocketIcon} className="w-12" alt="Rocket" />
            </div>
            <div className="flex flex-col">
              <h3>Hands-On</h3>
              <p>Lieber „Einfach mal machen“ als immer nur überdenken. Wir packen unsere Projekte an und bringen Ideen nach vorne.</p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap md:flex-no-wrap mt-8 items-center">
            <div className="block w-12 md:w-24 md:m-8">
              <img src={LightBulbIcon} className="w-full" alt="Lightbulb" />
            </div>
            <div className="flex flex-col">
              <h3>Ideas over titles.</h3>
              <p>Bei uns gibt es echte, flache Hierarchien. Wir gestalten Projekte gemeinsam und schätzen jede Idee. Bei uns gibt es kein von oben herab.</p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap md:flex-no-wrap mt-8 items-center">
            <div className="block w-12 md:m-8">
              <img src={LikeIcon} className="w-full" alt="Like" />
            </div>
            <div className="flex flex-col flex-grow">
              <h3>Teamwork.</h3>
              <p>Wir arbeiten als Team und unterstützen sowie motivieren uns gegenseitig.</p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap md:flex-no-wrap mt-8 items-center">
            <div className="flex flex-col w-12 md:m-8">
              <img src={LoveIcon} className="w-full" alt="Heart" />
            </div>
            <div className="flex flex-col">
              <h3>Commitment.</h3>
              <p>Wir lieben das was wir tun und stehen zu 100% hinter unserer Arbeit!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutUsPage;
